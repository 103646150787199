import styled, { css } from 'styled-components';
import theme from 'shared/assets/style/theme';
import generateRender from 'shared/utils/generateRender';

const LoyaltyPointsLogo = styled.img`
  height: 5rem;
  width: 5rem;
  object-fit: contain;

  @media screen and (max-width: 1500px) {
    height: 3rem;
    width: 3rem;
    ${({ theme }) => theme}
  }
  ${({ theme }) => theme}
`;

const PriceWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-left: 0.7rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 1600px) {
    margin-bottom: 0rem;
  }
`;

const ItemPlusOffer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 24%;
  padding-top: 0px;
  width: 100%;
  @media screen and (max-width: 1550px) {
    height: 26%;
    padding-top: 1rem;
    ${({ theme }) => theme}
  }

  @media screen and (max-width: 1300px) and (min-height: 900px) {
    height: 30%;
  }

  ${({ theme }) => theme}
`;

const VIPAccessWrapper = styled.div`
  font-size: 2.3vmin;
  @media screen and (max-width: 1550px) {
    font-size: 2vmin;
  }
`;

const ItemPrice = styled.div`
  font-size: 1.8rem;
  @media screen and (max-width: 1550px) {
    font-size: 1.3rem;
  }
`;

const PlusLoyaltyText = styled.div`
  font-size: 1.8rem;
  @media screen and (max-width: 1550px) {
    font-size: 1.3rem;
  }
  ${({ theme }) => theme}
`;

const Styles = {
  buyButtonImage: {
    width: '70%',
    height: '4.5rem',
    cursor: 'pointer',
  },
  buyButtonStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'auto',
    height: '4rem',
  },
  buyButtonText: {
    fontSize: '28px',
  },
  tileInnerStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common[1],
  },
};

const defaultVIPPackTitle = css`
  padding-top: 44%;
  @media screen and (max-width: 1300px) and (min-height: 880px) {
    padding-top: 64%;
  }
`;

const VIPPackTitleWithIcon = css`
  padding-top: 10px;
`;

const packageTitleTextStyle = generateRender({
  default: defaultVIPPackTitle,
  hri: VIPPackTitleWithIcon,
  millelacs: VIPPackTitleWithIcon,
  jamul: VIPPackTitleWithIcon,
});

const DurationFriendlyFull = styled.div`
  font-size: 2.2rem;
  line-height: 1;
  font-weight: 700;

  @media screen and (max-width: 1550px) {
    font-size: 1.7rem;
  }
  ${packageTitleTextStyle}
`;

export {
  LoyaltyPointsLogo,
  PriceWrapper,
  ButtonWrapper,
  ItemPlusOffer,
  VIPAccessWrapper,
  ItemPrice,
  PlusLoyaltyText,
  Styles,
  DurationFriendlyFull,
};

