// WARNING THIS IS AN AUTOMATICALLY-GENERATED FILE

export var imageSource = {
  LOYALTY_ICON: require("../assets/jamul/images/loyalty-icon.png"),
  JACKPOT_DISPLAYED_SCREEN: require("../assets/jamul/images/progressive-jackpots/jackpot-displayed-screen.png"),
  MESSAGE_INBOX_LOYALTY_PROMO: require("../assets/jamul/images/message-inbox-loyalty-promo.png"),
  SHOP_PURPLE_TRIANGLE_DIVIDER: require("../assets/jamul/images/shop-purple-triangle-divider.png"),
  VIP_ACCESS_STORE_TILE_3_PROMO: require("../assets/jamul/images/vip-access-store-tile-3-promo.png"),
  VIP_ACCESS_STORE_PROMO_FREE: require("../assets/jamul/images/vip-access-store-promo-free.png"),
  PURPLE_TIMER: require("../assets/jamul/images/purple-timer.png"),
  COIN_ICON: require("../assets/jamul/images/coin-assets/coin-icon.png"),
  COIN_GLOW: require("../assets/jamul/images/coin-assets/coin-glow.png"),
  SLOT_COINS: require("../assets/jamul/images/slot-coins.png"),
  ROUNDEDRECT_DISABLED_WIDE: require("../assets/jamul/images/roundedrect-disabled-wide.png"),
  SKIP_BUTTON: require("../assets/jamul/images/ftue/skip-button.png"),
  SEND_MESSAGE_ICON: require("../assets/jamul/images/settings/send-message-icon.png"),
  SETTINGS_DIVIDER: require("../assets/jamul/images/settings/settings-divider.png"),
  FORWARD_ARROW: require("../assets/jamul/images/settings/forward-arrow.png"),
  EXCLAMATION_ICON: require("../assets/jamul/images/settings/exclamation-icon.png"),
  ICON_SHOW: require("../assets/jamul/images/icon-show.png"),
  STARS_GRAPHIC: require("../assets/jamul/images/stars-graphic.png"),
  FORM_CONTINUE_BUTTON: require("../assets/jamul/images/form-continue-button.png"),
  TOP_BAR_BACKING: require("../assets/jamul/images/top-bar-backing.png"),
  CONTINUE_WITH_MAIL: require("../assets/jamul/images/link-guest-account/continue-with-mail.png"),
  APPLE: require("../assets/jamul/images/link-guest-account/apple.png"),
  GOOGLE: require("../assets/jamul/images/link-guest-account/google.png"),
  FACEBOOK: require("../assets/jamul/images/link-guest-account/facebook.png"),
  TIMER_COLLECT: require("../assets/jamul/images/timed-bonus-assets/timer-collect.png"),
  TIMED_BONUS_OPEN: require("../assets/jamul/images/timed-bonus-assets/timed-bonus-open.png"),
  TIMER_WAITING: require("../assets/jamul/images/timed-bonus-assets/timer-waiting.png"),
  TIMED_BONUS_CLOSED: require("../assets/jamul/images/timed-bonus-assets/timed-bonus-closed.png"),
  WATCH: require("../assets/jamul/images/bottom-bar-assets/watch.png"),
  VIP_ENTRY_LOCKED: require("../assets/jamul/images/bottom-bar-assets/vip-entry-locked.png"),
  MAIL: require("../assets/jamul/images/bottom-bar-assets/mail.png"),
  BACKING: require("../assets/jamul/images/bottom-bar-assets/backing.png"),
  REWARDS: require("../assets/jamul/images/bottom-bar-assets/rewards.png"),
  VIP_ROOM: require("../assets/jamul/images/bottom-bar-assets/vip-room.png"),
  BOTTOM_BAR_DIVIDER: require("../assets/jamul/images/bottom-bar-assets/divider.png"),
  SLOTS: require("../assets/jamul/images/bottom-bar-assets/slots.png"),
  GTP_MISSIONS_ICON_TEXT: require("../assets/jamul/images/bottom-bar-assets/gtp-missions-icon-text.png"),
  MISSIONS: require("../assets/jamul/images/bottom-bar-assets/missions.png"),
  WEB_BACKING: require("../assets/jamul/images/bottom-bar-assets/web-backing.png"),
  ROUNDED_RECT_DISABLED: require("../assets/jamul/images/rounded-rect-disabled.png"),
  CARD_BRONZE: require("../assets/jamul/images/card-bronze.png"),
  CLICK_HERE_BUTTON: require("../assets/jamul/images/click-here-button.png"),
  SHOP_PURPLE_CAP: require("../assets/jamul/images/shop-purple-cap.png"),
  SESSION_EXPIRED_IMAGE: require("../assets/jamul/images/session-expired-image/session-expired-image.png"),
  COINS_MAIL_ICON: require("../assets/jamul/images/coins-mail-icon.png"),
  ROUNDED_RECT_DANGER: require("../assets/jamul/images/rounded-rect-danger.png"),
  CREDIT_CARD_LOGOS: require("../assets/jamul/images/purchase-flow-assets/credit-card-logos.png"),
  PILE_OF_COINS: require("../assets/jamul/images/purchase-flow-assets/pile-of-coins.png"),
  WARNING_SYMBOL: require("../assets/jamul/images/purchase-flow-assets/warning-symbol.png"),
  VISA: require("../assets/jamul/images/purchase-flow-assets/visa.png"),
  MESSAGE_CHAT_BOX: require("../assets/jamul/images/purchase-flow-assets/message-chat-box.png"),
  MASTERCARD: require("../assets/jamul/images/purchase-flow-assets/mastercard.png"),
  AMEX: require("../assets/jamul/images/purchase-flow-assets/amex.png"),
  PURCHASE_LIGHT_DIVIDER: require("../assets/jamul/images/purchase-flow-assets/light-divider.png"),
  ROUNDEDRECT_PRIMARY_WIDE: require("../assets/jamul/images/roundedrect-primary-wide.png"),
  VIP_ACCESS_STORE_TILE_2_PROMO: require("../assets/jamul/images/vip-access-store-tile-2-promo.png"),
  SHOP_BEST_DEAL: require("../assets/jamul/images/shop-best-deal.png"),
  NEWS_ITEM_FRAME_9_SLICE: require("../assets/jamul/images/news-item-frame-9-slice.png"),
  COIN_GRAPHIC_SALE: require("../assets/jamul/images/promo-links-assets/coin-graphic-sale.png"),
  DIVIDER: require("../assets/jamul/images/divider.png"),
  TOP_BAR_LOYALTY_ICON: require("../assets/jamul/images/top-bar-assets/loyalty-icon.png"),
  IAP_SPLIT_GREEN: require("../assets/jamul/images/top-bar-assets/iap-split-green.png"),
  PURPLE_EXP_BAR: require("../assets/jamul/images/top-bar-assets/purple-exp-bar.png"),
  IAP_SPLIT_SALE: require("../assets/jamul/images/top-bar-assets/iap-split-sale.png"),
  MENU_HAMBURGER: require("../assets/jamul/images/top-bar-assets/menu-hamburger.png"),
  HOME_BUTTON: require("../assets/jamul/images/top-bar-assets/home-button.png"),
  EXP_SHIELD: require("../assets/jamul/images/top-bar-assets/exp-shield.png"),
  TOP_BAR_ASSETS_BACKING: require("../assets/jamul/images/top-bar-assets/backing.png"),
  IAP_SPLIT_PINK: require("../assets/jamul/images/top-bar-assets/iap-split-pink.png"),
  TOP_BAR_BAR_INSET: require("../assets/jamul/images/top-bar-assets/bar-inset.png"),
  IAP_BACKING: require("../assets/jamul/images/top-bar-assets/iap-backing.png"),
  TOP_BAR_COIN_ICON: require("../assets/jamul/images/top-bar-assets/coin-icon.png"),
  IAP_WHOLE_SALE: require("../assets/jamul/images/top-bar-assets/iap-whole-sale.png"),
  IAP_WHOLE: require("../assets/jamul/images/top-bar-assets/iap-whole.png"),
  VIP_TICKET: require("../assets/jamul/images/top-bar-assets/vip-ticket.png"),
  CLOCK_ICON: require("../assets/jamul/images/clock-icon.png"),
  SHOP_GOLD_TRIANGLE_DIVIDER: require("../assets/jamul/images/shop-gold-triangle-divider.png"),
  ICON_CASINODOLLARS: require("../assets/jamul/images/icon-casinodollars.png"),
  SHOP_POPULAR: require("../assets/jamul/images/shop-popular.png"),
  ERROR_BACKGROUND: require("../assets/jamul/images/error-screen/background.jpg"),
  MESSAGE: require("../assets/jamul/images/error-screen/message.png"),
  RATE_NOW_BUTTON: require("../assets/jamul/images/rate-now-button.png"),
  RIGHT_ARROW: require("../assets/jamul/images/right-arrow.png"),
  GOOGLE_BUTTON: require("../assets/jamul/images/start/buttons/google-button.png"),
  MEMBER_BUTTON: require("../assets/jamul/images/start/buttons/member-button.png"),
  LOGIN_GUEST_BUTTON: require("../assets/jamul/images/start/buttons/login-guest-button.png"),
  GUEST_BUTTON: require("../assets/jamul/images/start/buttons/guest-button.png"),
  APPLE_BUTTON: require("../assets/jamul/images/start/buttons/apple-button.png"),
  FACEBOOK_BUTTON: require("../assets/jamul/images/start/buttons/facebook-button.png"),
  SPLASH_BG: require("../assets/jamul/images/start/splash-bg.jpg"),
  START_SCREEN_LOGO: require("../assets/jamul/images/start/start-screen-logo.png"),
  SPLASH_BAR_BACKING: require("../assets/jamul/images/start/splash-bar-backing/splash-bar-backing.png"),
  TRUCK8: require("../assets/jamul/images/animation/truck8.png"),
  TRUCK10: require("../assets/jamul/images/animation/truck10.png"),
  TRUCK11: require("../assets/jamul/images/animation/truck11.png"),
  TRUCK9: require("../assets/jamul/images/animation/truck9.png"),
  TRUCK13: require("../assets/jamul/images/animation/truck13.png"),
  TRUCK12: require("../assets/jamul/images/animation/truck12.png"),
  TRUCK16: require("../assets/jamul/images/animation/truck16.png"),
  TRUCK17: require("../assets/jamul/images/animation/truck17.png"),
  TRUCK15: require("../assets/jamul/images/animation/truck15.png"),
  TRUCK14: require("../assets/jamul/images/animation/truck14.png"),
  TRUCK1: require("../assets/jamul/images/animation/truck1.png"),
  TRUCK18: require("../assets/jamul/images/animation/truck18.png"),
  TRUCK2: require("../assets/jamul/images/animation/truck2.png"),
  TRUCK3: require("../assets/jamul/images/animation/truck3.png"),
  TRUCK7: require("../assets/jamul/images/animation/truck7.png"),
  TRUCK6: require("../assets/jamul/images/animation/truck6.png"),
  TRUCK4: require("../assets/jamul/images/animation/truck4.png"),
  TRUCK5: require("../assets/jamul/images/animation/truck5.png"),
  ROUNDED_RECT_QUATERNARY: require("../assets/jamul/images/rounded-rect-quaternary.png"),
  DOT_UNSELECTED: require("../assets/jamul/images/slider/dot-unselected.png"),
  DOT_SELECTED: require("../assets/jamul/images/slider/dot-selected.png"),
  MAX_BET: require("../assets/jamul/images/level-up/max-bet.png"),
  LEVEL_UP_LIGHT_DIVIDER: require("../assets/jamul/images/level-up/light-divider.png"),
  CARD_GOLD: require("../assets/jamul/images/card-gold.png"),
  MODAL_BACKING_9_SLICE: require("../assets/jamul/images/modal-backing-9-slice.png"),
  MODAL_BACKING_9_SLICE_WEB: require("../assets/jamul/images/modal-backing-9-slice-web.png"),
  BACK_ARROW: require("../assets/jamul/images/back-arrow.png"),
  LOYALTY_ICON_WEB: require("../assets/jamul/images/loyalty-icon-web.png"),
  MOBILE_BACKGROUND_INPUT_FORMS: require("../assets/jamul/images/mobile-background-input-forms.png"),
  TIMER_BACKING: require("../assets/jamul/images/watch-and-earn-assets/timer-backing.png"),
  WATCH_EARN_POPUP_ICON: require("../assets/jamul/images/watch-and-earn-assets/watch&earn-popup-icon.png"),
  COINS_TEX: require("../assets/jamul/images/watch-and-earn-assets/coins-tex.png"),
  HEART_ON: require("../assets/jamul/images/favourites/heart-on.png"),
  GAMES_IMAGE: require("../assets/jamul/images/favourites/games-image.png"),
  HEART_OFF: require("../assets/jamul/images/favourites/heart-off.png"),
  CARD_PLATINUM: require("../assets/jamul/images/card-platinum.png"),
  SECTION: require("../assets/jamul/images/section.png"),
  ROUNDED_RECT_PRIMARY: require("../assets/jamul/images/rounded-rect-primary.png"),
  SHOP_VIP_DIVIDER_GLOWS: require("../assets/jamul/images/shop-vip-divider-glows.png"),
  SHOP_GOLD_BACKING: require("../assets/jamul/images/shop-gold-backing.png"),
  ACCESS_OPTIONS: require("../assets/jamul/images/vip-assets/access-options.png"),
  PURCHASE_SUCCEEDED: require("../assets/jamul/images/vip-assets/purchase-succeeded.png"),
  VIP_BACKGROUND: require("../assets/jamul/images/vip-assets/vip-background.jpg"),
  GLOWING_CONTAINER: require("../assets/jamul/images/vip-assets/glowing-container.png"),
  NAVIGATION_DARKER: require("../assets/jamul/images/vip-assets/navigation-darker.png"),
  CARET_DOWN: require("../assets/jamul/images/caret-down.png"),
  CHECKMARK: require("../assets/jamul/images/checkmark.png"),
  GENERAL_BACKGROUND: require("../assets/jamul/images/background.png"),
  PROMO_IMAGE_COINS: require("../assets/jamul/images/promo-image-coins.png"),
  POPUP_FRAMING: require("../assets/jamul/images/popup-framing.png"),
  POPUP_FRAMING_MOBILE: require("../assets/jamul/images/popup-framing-mobile.png"),
  FLY_OUT_STAR: require("../assets/jamul/images/fly-out-star.png"),
  SMART_BANNER_LOGO: require("../assets/jamul/images/smart-banner-logo.png"),
  GAMES_LOCK: require("../assets/jamul/images/lobby-assets/games-lock.png"),
  LOBBY_COMING_SOON: require("../assets/jamul/images/lobby-assets/coming-soon.png"),
  LOBBY_BACKGROUND: require("../assets/jamul/images/lobby-assets/background.jpg"),
  MESSAGE_BACKGROUND: require("../assets/jamul/images/social/message-background.png"),
  UNDER_MAINTENANCE: require("../assets/jamul/images/social/under-maintenance.png"),
  SOCIAL_COMING_SOON: require("../assets/jamul/images/social/coming-soon.png"),
  VIP_ACCESS_STORE_TILE_1_PROMO: require("../assets/jamul/images/vip-access-store-tile-1-promo.png"),
  DIALOG: require("../assets/jamul/images/dialog.png"),
  VIP_PASS: require("../assets/jamul/images/vip-pass.png"),
  WELCOME_BONUS: require("../assets/jamul/images/welcome-bonus-assets/welcome-bonus.png"),
  WELCOME_BONUS_WEB: require("../assets/jamul/images/welcome-bonus-assets/welcome-bonus-web.png"),
  BADGE_I_OS: require("../assets/jamul/images/tracking-url/badge-i-os.png"),
  BADGE_WEB: require("../assets/jamul/images/tracking-url/badge-web.png"),
  TRACKING_URL_LOGO: require("../assets/jamul/images/tracking-url/tracking-url-logo.png"),
  GREY_BUTTON_LANDING: require("../assets/jamul/images/tracking-url/grey-button-landing.png"),
  GREEN_BUTTON_LANDING: require("../assets/jamul/images/tracking-url/green-button-landing.png"),
  BADGE_ANDROID: require("../assets/jamul/images/tracking-url/badge-android.png"),
  MAIL_MESSAGE_DEFAULT: require("../assets/jamul/images/mail-message-default.png"),
  LOGO: require("../assets/jamul/images/logo.png"),
  TIMER_DISPLAYED_SCREEN: require("../assets/jamul/images/sneak-peek/timer-displayed-screen.png"),
  SNEAK_PEEK_ONBOARDING: require("../assets/jamul/images/sneak-peek/sneak-peek-onboarding.png"),
  SNEAK_PEEK_BACKGROUND: require("../assets/jamul/images/sneak-peek/sneak-peek-background.png"),
  ICON_SNEAK_PEEK: require("../assets/jamul/images/sneak-peek/icon-sneak-peek.png"),
  PROMOTIONAL_GRAPHIC: require("../assets/jamul/images/promotional-graphics/promotional-graphic.png"),
  FULL_MODAL_HEADER: require("../assets/jamul/images/full-modal-header.png"),
  FULL_MODAL_HEADER_MOBILE: require("../assets/jamul/images/full-modal-header-mobile.png"),
  COINS_TAB_ACTIVE: require("../assets/jamul/images/coin-store-assets/coins-tab-active.png"),
  UPSELL_TAB_BEST_DEAL: require("../assets/jamul/images/coin-store-assets/upsell-tab-best-deal.png"),
  ITEM_ROW_PURPLE: require("../assets/jamul/images/coin-store-assets/item-row-purple.png"),
  COIN_GLOWING_CONTAINER: require("../assets/jamul/images/coin-store-assets/glowing-container.png"),
  COIN_GLOWING_CONTAINER_MOBILE: require("../assets/jamul/images/coin-store-assets/glowing-container-mobile.png"),
  UPSELL_TAB_POPULAR: require("../assets/jamul/images/coin-store-assets/upsell-tab-popular.png"),
  UPSELL_TAB_AMAZING: require("../assets/jamul/images/coin-store-assets/upsell-tab-amazing.png"),
  VIP_TAB_ACTIVE: require("../assets/jamul/images/coin-store-assets/vip-tab-active.png"),
  ITEM_ROW_GOLD: require("../assets/jamul/images/coin-store-assets/item-row-gold.png"),
  LOYALTY_ICON_BONUS: require("../assets/jamul/images/coin-store-assets/loyalty-icon-bonus.png"),
  DEFAULT_BACKGROUND: require("../assets/jamul/images/coin-store-assets/default-background.jpg"),
  VIP_HOUR_TILE_3: require("../assets/jamul/images/coin-store-assets/vip-tiles/vip-hour-tile-3.png"),
  VIP_HOUR_TILE_2: require("../assets/jamul/images/coin-store-assets/vip-tiles/vip-hour-tile-2.png"),
  VIP_HOUR_TILE_1: require("../assets/jamul/images/coin-store-assets/vip-tiles/vip-hour-tile-1.png"),
  VIP_HOUR_TILE_1STAR: require("../assets/jamul/images/coin-store-assets/vip-tiles/vip-hour-tile-1star.png"),
  VIP_HOUR_TILE_3STARS: require("../assets/jamul/images/coin-store-assets/vip-tiles/vip-hour-tile-3stars.png"),
  VIP_HOUR_TILE_2STARS: require("../assets/jamul/images/coin-store-assets/vip-tiles/vip-hour-tile-2stars.png"),
  VIP_HOUR_TILE_FREE: require("../assets/jamul/images/coin-store-assets/vip-tiles/vip-hour-tile-free.png"),
  TOP_BAR_IAP_BACKING: require("../assets/jamul/images/top-bar-iap-backing.png"),
  SHOP_VIP_DIVIDER: require("../assets/jamul/images/shop-vip-divider.png"),
  ICON_HIDE: require("../assets/jamul/images/icon-hide.png"),
  CLOSE_BUTTON: require("../assets/jamul/images/close-button.png"),
  ROUNDED_RECT_SECONDARY: require("../assets/jamul/images/rounded-rect-secondary.png"),
  ORANGE_TIMER: require("../assets/jamul/images/orange-timer.png"),
  POINTERBOX: require("../assets/jamul/images/pointerbox.png"),
  VISA_MC: require("../assets/jamul/images/visa-mc.png"),
  SHOP_AMAZING: require("../assets/jamul/images/shop-amazing.png"),
  ROUNDED_RECT_BLUE: require("../assets/jamul/images/rounded-rect-blue.png"),
  COIN: require("../assets/jamul/images/coin-icon.png"),
  TOAST_BACKGROUND: require("../assets/jamul/images/toast-assets/background.png"),
  TILE_BACKER_9SLICE: require("../assets/jamul/images/tile-backer-9slice.png"),
  DOWNLOAD_ICON: require("../assets/jamul/images/embedded-games-assets/download-icon.png"),
  ACCEPT_BUTTON: require("../assets/jamul/images/notifications-permissions-assets/accept-button.png"),
  NOTIFICATIONS_PERMISSIONS: require("../assets/jamul/images/notifications-permissions-assets/notifications-permissions.png"),
  INFO_POPUP: require("../assets/jamul/images/favorites-info-popup/info-popup.png"),
  BACK_BUTTON: require("../assets/jamul/images/back-button.png"),
  TAB_FRONT: require("../assets/jamul/images/tabs-assets/tab-front.png"),
  TAB_BACK: require("../assets/jamul/images/tabs-assets/tab-back.png"),
  COINS_DEFAULT: require("../assets/jamul/images/coins-default.png"),
  CHECKBOX: require("../assets/jamul/images/checkbox.png"),
  MODAL_BACKER: require("../assets/jamul/images/modal-backer.png"),
  DAILY_BONUS_PRIZES_BOTTOM_BAR: require("../assets/jamul/images/daily-spin/daily-bonus-prizes-bottom-bar.png"),
  SPLASH_SCREEN_1: require("../assets/jamul/images/splash-screen-1.png"),
  TSC_CARDS: require("../assets/jamul/images/tsc-cards.png"),
  CARD_DIAMOND: require("../assets/jamul/images/card-diamond.png"),
  OUT_OF_COINS_STATIC_IMAGE: require("../assets/jamul/images/out-of-coins-assets/out-of-coins-static-image.png"),
  MARK: require("../assets/jamul/images/mark.png"),
  PRIVACY_POLICY: require("../assets/jamul/images/terms-and-conditions-buttons/privacy-policy.png"),
  TERMS_CONDITIONS: require("../assets/jamul/images/terms-and-conditions-buttons/terms-conditions.png"),
  MAIL_ICON_OPEN: require("../assets/jamul/images/mail-icon-open.png"),
  LINK_NOW_BUTTON: require("../assets/jamul/images/link-account-info/link-now-button.png"),
  PROMO_IMAGE_LOYALTY: require("../assets/jamul/images/promo-image-loyalty.png"),
  RED_TIMER: require("../assets/jamul/images/red-timer.png"),
  CARD_XCARD: require("../assets/jamul/images/card-xcard.png"),
  LOYALTY_MAIL_ICON: require("../assets/jamul/images/loyalty-mail-icon.png"),
  CLOCK_ICON_GLOWING: require("../assets/jamul/images/clock-icon-glowing.png"),
  SHOP_GOLD_CAP: require("../assets/jamul/images/shop-gold-cap.png"),
  PINK_BUTTON: require("../assets/jamul/images/pink-button.png"),
  ROUNDEDRECT_TERTIARY_WIDE: require("../assets/jamul/images/roundedrect-tertiary-wide.png"),
  ORANGE_BUTTON: require("../assets/jamul/images/orange-button.png"),
  QUEST20_LOCK: require("../assets/jamul/images/quest20-lock.png"),
  ICON_QUESTION: require("../assets/jamul/images/icon-question.png"),
  CARD_PREMIER: require("../assets/jamul/images/card-premier.png"),
  LTO_PROMO_IMAGE: require("../assets/jamul/images/lto-promo-image/lto-promo-image.png"),
  SIDEBAR_ARROW_BUTTON: require("../assets/jamul/images/side-bar-assets/sidebar-arrow-button.png"),
  SIDEBAR_BUTTON: require("../assets/jamul/images/side-bar-assets/sidebar-button.png"),
  SIDEBAR_BUTTON_HIGHLIGHTED: require("../assets/jamul/images/side-bar-assets/sidebar-button-highlighted.png"),
  CHECKBOX_DESELECTED: require("../assets/jamul/images/checkbox-deselected.png"),
  ROUNDED_RECT_TERTIARY: require("../assets/jamul/images/rounded-rect-tertiary.png"),
  CARD_SILVER: require("../assets/jamul/images/card-silver.png"),
  EMPTY_INFO: require("../assets/jamul/images/empty-favorites-message/empty-info.png"),
  CARD_ELITE: require("../assets/jamul/images/card-elite.png"),
  LINK_BUTTON: require("../assets/jamul/images/rewards-center-assets/link-button.png"),
  REWARD_TSC_CARDS: require("../assets/jamul/images/rewards-center-assets/tsc-cards.png"),
  VIP_MESSAGE_ICON: require("../assets/jamul/images/vip-message-assets/vip-message-icon.png"),
  VIP_MESSAGE_BACKGROUND: require("../assets/jamul/images/vip-message-assets/vip-message-background.png"),
  LIGHT_DIVIDER: require("../assets/jamul/images/light-divider.png"),
  SPRITESHEET: require("../assets/jamul/images/spritesheet.png"),
  SCROLL_INDICATOR: require("../assets/jamul/images/scroll-indicator.png"),
  LOADING_FILL_SIDE: require("../assets/jamul/images/asset-preloader/loading-fill-side.png"),
  LOADING_BAR_INSET: require("../assets/jamul/images/asset-preloader/loading-bar-inset.png"),
  LOADING_LOGO: require("../assets/jamul/images/asset-preloader/loading-logo.png"),
  LOADING_FILL_CENTER: require("../assets/jamul/images/asset-preloader/loading-fill-center.png"),
  LOADING_BAR_BACKING: require("../assets/jamul/images/asset-preloader/loading-bar-backing.png"),
  LOADING_BAR_FILL: require("../assets/jamul/images/asset-preloader/loading-bar-fill.png"),
  LOADING_SCREEN: require("../assets/jamul/images/asset-preloader/loading-screen.jpg"),
  LOADING_LOGO_LEFT: require("../assets/jamul/images/asset-preloader/loading-logo-left.png"),
  CLOSE_BUTTON_DANGER: require("../assets/jamul/images/close-button-danger.png"),
  MESSAGE_COINS_BACKGROUND: require("../assets/jamul/images/social/messages/coins-message-background.png"),
  MESSAGE_COINS_ICON: require("../assets/jamul/images/social/messages/coins-message-icon.png"),
  MESSAGE_LOYALTY_BACKGROUND: require("../assets/jamul/images/social/messages/loyalty-message-background.png"),
  MESSAGE_LOYALTY_ICON: require("../assets/jamul/images/social/messages/loyalty-message-icon.png"),
  MESSAGE_LOYALTY_SUBTITLE_ICON: require("../assets/jamul/images/social/messages/loyalty-message-subtitle-icon.png"),
  MESSAGE_SNEAKPEEK_BACKGROUND: require("../assets/jamul/images/social/messages/sneakpeek-message-background.png"),
  MESSAGE_SNEAKPEEK_ICON: require("../assets/jamul/images/social/messages/sneakpeek-message-icon.png"),
  MESSAGE_VIP_BACKGROUND: require("../assets/jamul/images/social/messages/vip-message-background.png"),
  MESSAGE_VIP_ICON: require("../assets/jamul/images/social/messages/vip-message-icon.png"),
  MESSAGE_DEFAULT_BACKGROUND: require("../assets/jamul/images/social/messages/default-message-background.png"),
  INPUT_EMAIL_ICON_REGULAR: require("../assets/jamul/images/input-icons/email-icon-regular.png"),
  INPUT_EMAIL_ICON_ERROR: require("../assets/jamul/images/input-icons/email-icon-error.png"),
  INPUT_PASSWORD_ICON_REGULAR: require("../assets/jamul/images/input-icons/password-icon-regular.png"),
  INPUT_PASSWORD_ICON_ERROR: require("../assets/jamul/images/input-icons/password-icon-error.png"),
  SESSION_EXPIRED_CLOCK_ICON: require("../assets/jamul/images/session-expired-image/clock-icon.png"),
  EVENTS_ICON_ACTIVE_BLOCK: require("../assets/jamul/images/bottom-bar-assets/events-icon-active-block.png"),
  EVENTS_ICON_ACTIVE_CHECK: require("../assets/jamul/images/bottom-bar-assets/events-icon-active-check.png"),
  EVENTS_ICON_ACTIVE_PEN: require("../assets/jamul/images/bottom-bar-assets/events-icon-active-pen.png"),
  EVENTS_ICON_ACTIVE_TEXT: require("../assets/jamul/images/bottom-bar-assets/events-icon-active-text.png"),
  MISSIONS_ICON_ARROW: require("../assets/jamul/images/bottom-bar-assets/missions-icon-arrow.png"),
  MISSIONS_ICON_CIRCLE: require("../assets/jamul/images/bottom-bar-assets/missions-icon-circle.png"),
  REWARD_CENTER_ICON_GLOW: require("../assets/jamul/images/bottom-bar-assets/reward-center-icon-glow.png")
};