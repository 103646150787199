import React from 'react';
import Modal from '../../../../components/JSModal';
import theme from 'shared/assets/style/theme';
import QueryParamsProxy from 'shared/utils/queryParamProxy';
import RouteParamConstants from 'shared/utils/routeParamConstants';
import Screens from 'shared/screens';
import useRewardCenterOfferDetails from 'shared/screens/RewardCenter/hooks/useRewardCenterOfferDetails';
import {
  DetailsContainer,
  Main,
  MainContainer,
  modalStyle,
  ModalMainBodyStyle,
  ActivityIndicatorContainer,
  TitleText,
  Divider,
} from './styledComponents';
import ActivityIndicator from '../../../../components/ActivityIndicator/ActivityIndicator';
import OfferDetailsDescription from './components/OfferDetailsDescription';
import OfferDetailsCarousel from './components/OfferDetailsCarousel';
import KycConsentPopUp from './components/KycConsentPopUp';
import PurchaseResponsePopUp from './components/PurchaseResponsePopUp';
import { TEXT_KEY, getText } from 'shared/utils/localization';
import { PURCHASE_RESPONSE } from 'shared/constants';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

const RewardCenterOfferDetail = props => {
  const { selectedOffer, setSelectedOffer, rewardCenterAssets, purchaseResponse } = props;

  const useRewardCenterOfferDetailsHookData = useRewardCenterOfferDetails(props);
  const { offerDetails, isLoading, isVipPurchase, onDismissPurchaseResponse, showKycPopUp } =
    useRewardCenterOfferDetailsHookData;

  const purchaseSuccess = purchaseResponse?.status === PURCHASE_RESPONSE.SUCCESS;

  return (
    <>
      <Modal
        modalMainBodyStyle={ModalMainBodyStyle}
        style={modalStyle}
        isVisible={!!selectedOffer}
        onBackButtonPress={() => setSelectedOffer(null)}
        dialogClassName={'gtp-modal-reward-center'}
      >
        <MainContainer>
          <Main>
            {isLoading ? (
              <ActivityIndicatorContainer>
                <ActivityIndicator color={`${theme.palette.primary[9]}`} style={{ flex: 1 }} />
              </ActivityIndicatorContainer>
            ) : (
              <>
                <div>
                  <TitleText>{getText(TEXT_KEY.REWARD_CENTER_OFFER_DETAILS_TITLE)}</TitleText>
                  <Divider
                    source={assetSource(ASSET_KEY.SHOP_VIP_DIVIDER_GLOWS)}
                    ibw={require(asset`shop-vip-divider-glows.ibw`)}
                    manualAssetScale={3}
                  />
                </div>
                <DetailsContainer>
                  <OfferDetailsCarousel
                    {...useRewardCenterOfferDetailsHookData}
                    rewardCenterAssets={rewardCenterAssets}
                  />
                  <OfferDetailsDescription
                    {...useRewardCenterOfferDetailsHookData}
                    {...offerDetails}
                    rewardCenterAssets={rewardCenterAssets}
                  />
                </DetailsContainer>
              </>
            )}
          </Main>
        </MainContainer>
      </Modal>
      {purchaseResponse && (
        <PurchaseResponsePopUp
          {...useRewardCenterOfferDetailsHookData}
          isVipSuccessPurchase={isVipPurchase && purchaseSuccess}
          onDismissPurchaseResponse={onDismissPurchaseResponse}
          rewardCenterAssets={rewardCenterAssets}
        />
      )}
      {showKycPopUp && <KycConsentPopUp {...useRewardCenterOfferDetailsHookData} />}
    </>
  );
};

export default RewardCenterOfferDetail;

