// DEPRECATED: 1 || 0 flags
export var ACCOUNT_DELETION = 'account_deletion';
export var ODR_GAMES = 'odr_games';
export var SNEAK_PEEK = 'sneak_peek';

// JSON flags
export var FE_ACCOUNT_DELETION = 'fe_account_deletion';
export var FE_ODR_GAMES = 'fe_odr_games';
export var FE_SNEAK_PEEK = 'fe_sneak_peek';
export var FE_JSONSCHEMA_VALIDATION = 'fe_jsonschema_validation';
export var FE_MISSIONS = 'fe_missions';
export var FE_MISSYOU_NOTIFICATION = 'fe_missyou_notification';
export var FE_PROGRESSIVE_JACKPOTS = 'fe_progressive_jackpots';
export var FE_LINK_GUEST_LOGIN = 'fe_link_guest_login';
export var FE_ANIMATION = 'fe_animation';
export var FE_EVENTS = 'fe_sweepstakes';
export var FE_REWARD_CENTER = 'fe_reward_center';
export var FE_TIMEOUTS = 'fe_timeouts';
export var FE_SOCIAL = 'fe_social';
export var FE_FIREBASE_PERFORMANCE = 'fe_firebase_performance';
export var FE_MISSIONS_ICON_ANIMATION = 'fe_missions_icon_animation';
export var FE_CACHE_MAX_AGE = 'fe_cache_max_age';
export var FE_TIER_INFO_TOOLTIP = 'fe_tier_info_tooltip';
export var FE_CREDIT_CARD_TYPE = 'fe_credit_card_type';
export var FE_LOCALIZATION = 'fe_localization';
export var FE_ASSETS_CDN = 'fe_assets_cdn';

/**
 * This object is the array to be used to validate with our provider.
 */
export var FLAG_VALUES = [FE_ACCOUNT_DELETION, FE_ODR_GAMES, FE_SNEAK_PEEK, FE_JSONSCHEMA_VALIDATION, FE_MISSYOU_NOTIFICATION, FE_PROGRESSIVE_JACKPOTS, FE_MISSIONS, FE_LINK_GUEST_LOGIN, FE_ANIMATION, FE_EVENTS, FE_REWARD_CENTER, FE_TIMEOUTS, FE_SOCIAL, FE_FIREBASE_PERFORMANCE, FE_MISSIONS_ICON_ANIMATION, FE_CACHE_MAX_AGE, FE_TIER_INFO_TOOLTIP, FE_CREDIT_CARD_TYPE, FE_LOCALIZATION, FE_ASSETS_CDN];

/**
 * This object is the default array to use in case of an error.
 */
export var FLAG_DEFAULT_VALUES = {
  fe_provider_sync_coins: {
    enabled: false
  },
  fe_account_deletion: {
    enabled: true
  },
  fe_jsonschema_validation: {
    '/v2/getTermsAndConditionsUrl': {
      GET: {
        enabled: true,
        skip_errors: false
      }
    },
    '/v2/getFbAppId': {
      GET: {
        enabled: true,
        skip_errors: false
      }
    },
    '/missions/player': {
      GET: {
        enabled: true,
        skip_errors: false
      },
      POST: {
        enabled: true,
        skip_errors: false
      }
    },
    '/assets': {
      GET: {
        enabled: true,
        skip_errors: false
      }
    },
    '/games/progressiveJackpots': {
      GET: {
        enabled: true,
        skip_errors: false
      }
    },
    '/v2/getDailyWheelBonusData': {
      GET: {
        enabled: true,
        skip_errors: false
      }
    },
    '/v2/guestlinkemail': {
      POST: {
        enabled: true,
        skip_errors: false
      }
    },
    '/v2/guestLinkGoogleAccount': {
      POST: {
        enabled: true,
        skip_errors: false
      }
    },
    '/v2/guestLinkAppleAccount': {
      POST: {
        enabled: true,
        skip_errors: false
      }
    },
    '/v2/guestLinkFacebookAccount': {
      POST: {
        enabled: true,
        skip_errors: false
      }
    },
    '/v2/levelup/': {
      GET: {
        enabled: true,
        skip_errors: false
      }
    },
    '/v2/getplayerprofile/': {
      GET: {
        enabled: true,
        skip_errors: false
      }
    },
    '/v2/getCasinoDollarBalance/': {
      GET: {
        enabled: true,
        skip_errors: false
      }
    },
    '/v2/toggleSound/': {
      GET: {
        enabled: true,
        skip_errors: false
      }
    },
    '/v2/toggleMusic/': {
      GET: {
        enabled: true,
        skip_errors: false
      }
    },
    '/v2/deleteAccount': {
      GET: {
        enabled: true,
        skip_errors: false
      }
    },
    '/v2/updatePlayerProfile/': {
      POST: {
        enabled: true,
        skip_errors: false
      }
    },
    '/v2/togglemarketingemails': {
      POST: {
        enabled: true,
        skip_errors: false
      }
    },
    '/v2/togglenotifications': {
      POST: {
        enabled: true,
        skip_errors: false
      }
    },
    'triggers/notify': {
      POST: {
        enabled: true,
        skip_errors: true
      }
    },
    '/v2/claimTimedBonus/': {
      GET: {
        enabled: true,
        skip_errors: false
      }
    },
    '/v2/submitbonusablelink/': {
      POST: {
        enabled: true,
        skip_errors: false
      }
    },
    '/v2/collectwelcomebonus/': {
      POST: {
        enabled: true,
        skip_errors: false
      }
    }
  },
  fe_missyou_notification: {
    enabled: false,
    title: '💕We miss you!',
    body: 'SPIN 🎰 back in and PLAY your favorite game!',
    inactivityHours: 72
  },
  fe_missions: {
    enabled: false
  },
  fe_animation: {
    'navigation-bar-shine': {
      enabled: false
    },
    'missions-progress-shine': {
      enabled: false
    },
    'navigation-buy-button-shine': {
      enabled: false
    },
    'reward-center-icon-glow': {
      enabled: false
    },
    'events-icon': {
      enabled: false
    },
    'pinned-panel': {
      enabled: false
    },
    'daily-spin-reward': {
      enabled: false
    },
    'collect-bonus': {
      enabled: false
    }
  },
  fe_sweepstakes: {
    enabled: false
  },
  fe_reward_center: {
    enabled: false
  },
  fe_timeouts: {
    '/v2/initrewards/': {
      POST: 10000
    },
    '/assets': {
      GET: 5000
    },
    '/v2/claimDailyBonus/': {
      GET: 7000
    },
    '/v2/initgame/': {
      GET: 7000
    }
  },
  fe_firebase_performance: {
    enabled: false
  },
  fe_missions_icon_animation: {
    enabled: false
  },
  fe_social: {
    enabled: false
  },
  fe_tier_info_tooltip: {
    enabled: false
  },
  fe_credit_card_type: {
    0: {
      value: 'visa',
      label: 'VISA'
    },
    1: {
      value: 'mastercard',
      label: 'MasterCard'
    }
  },
  fe_localization: {
    enabled: false
  },
  fe_assets_cdn: {
    enabled: true
  }
};