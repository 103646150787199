import { Form } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import generateRender from 'shared/utils/generateRender';
import theme from 'shared/assets/style/theme';

const TextInputDefault = styled(Form.Control).attrs({
  className: 'transparentInput',
})``;

const TextInputMillelacs = styled(Form.Control)`
  background: rgb(0 39 62);
  border-radius: 0.6em;
  border: none;
  height: 42px;
  color: ${theme.palette.common[1]};
  padding-left: ${({ icon }) => (icon ? 30 : 10)}px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${theme.palette.common[1]};
  }
  &:focus {
    box-shadow: none;
    outline: none;
    background: rgb(0 39 62);
    border-radius: 0.6em;
    border: none;
    color: ${theme.palette.common[1]};
  }

  ${({ error, boxErrorLayout }) => error && boxErrorLayout && BoxErrorLayout}
`;

const TextInputJamul = styled(Form.Control)`
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0.6em;
  height: 42px;
  color: ${theme.palette.common[1]};
  padding-left: ${({ icon }) => (icon ? 30 : 10)}px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${theme.palette.common[1]};
  }
  &:focus {
    box-shadow: none;
    outline: none;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 0.6em;
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: ${theme.palette.common[1]};
  }

  ${({ error, boxErrorLayout }) => error && boxErrorLayout && BoxErrorLayout}
`;

const BoxErrorLayout = css`
  border-bottom-left-radius: 0.6em;
  border-bottom-right-radius: 0.6em;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top: none;
  &:focus {
    border-bottom-left-radius: 0.6em;
    border-bottom-right-radius: 0.6em;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top: none;
  }
`;

export const TextInputBootstrap = generateRender({
  default: TextInputJamul,
  millelacs: TextInputMillelacs,
});

