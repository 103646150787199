import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { getProgressiveJackpotsQuery } from './queries';
export var progressiveJackpotsApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      getProgressiveJackpots: builder.query(getProgressiveJackpotsQuery)
    };
  }
});
progressiveJackpotsApi.enhanceEndpoints({
  addTagTypes: ['ProgressiveJackpots']
});
var useGetProgressiveJackpotsQuery = progressiveJackpotsApi.useGetProgressiveJackpotsQuery,
  getProgressiveJackpots = progressiveJackpotsApi.endpoints.getProgressiveJackpots;
export { useGetProgressiveJackpotsQuery, getProgressiveJackpots };